var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "report" },
    [
      _c("div", { staticClass: "no-print mt-5" }),
      _vm.report
        ? _c("ReportPage", {
            attrs: {
              report: _vm.report,
              startDate: _vm.startDate,
              endDate: _vm.endDate,
              dateGrouping: _vm.dateGrouping,
              related_ids: _vm.relatedIds,
              timezone: _vm.timezone,
              related_type: _vm.relatedType
            },
            on: {
              data_ready: function($event) {
                _vm.dataReady = true
              }
            }
          })
        : _vm._e(),
      _c("div", { staticClass: "report-header" }, [
        _c("div", [
          _c("i", {
            directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
            staticClass: "icon-button",
            class: _vm.$config.icons.general.back,
            attrs: { title: "Back to Reports" },
            on: {
              click: function($event) {
                return _vm.$router.back()
              }
            }
          })
        ]),
        _vm._m(0),
        _c("div")
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "report-heading" }, [
      _c("h1", [_vm._v("Protekt Dashboard")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }