<template>
  <div class="report">
    <div class="no-print mt-5"></div>
    <ReportPage v-if="report"
                :report="report" :startDate="startDate" :endDate="endDate" :dateGrouping="dateGrouping"
                :related_ids="relatedIds" :timezone="timezone" :related_type="relatedType"
                @data_ready="dataReady = true"></ReportPage>

    <div class="report-header">
      <div><i
        class="icon-button"
        :class="$config.icons.general.back"
        v-b-tooltip
        title="Back to Reports"
        @click="$router.back()"
      ></i></div>
      <div class="report-heading"><h1>Protekt Dashboard</h1></div>
      <div>
<!--        <i v-if="dataReady"-->
<!--           class="icon-button no-print"-->
<!--           :class="$config.icons.general.print"-->
<!--           v-b-tooltip-->
<!--           title="Print View"-->
<!--           @click="print()"-->
<!--        ></i>-->
<!--        <i v-if="dataReady"-->
<!--           class="icon-button"-->
<!--           :class="$config.icons.general.export"-->
<!--           v-b-tooltip-->
<!--           title="Export Table"-->
<!--           @click="$bvModal.show('modal-export-data')"-->
<!--        ></i>-->
      </div>
    </div>
  </div>
</template>

<script>

import ReportPage from '@/components/stats/ReportPage'
import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import ReportHelper from '@/components/helpers/ReportHelper'

export default {
  name: 'dashboard-view',
  components: {
    ReportPage
  },
  data: function () {
    return {
      reportId: null,
      startDate: null,
      endDate: null,
      dateGrouping: null,
      dateMode: null,
      report: null,
      timezone: null,
      dataReady: false,
      relatedIds: null,
      relatedType: null
    }
  },
  async mounted() {
    this.config = await this.$configService.getConfig('dashboard')
    if (this.config) {
      this.reportId = this.config.report_id
      this.dateGrouping = this.config.time_grouping
      this.dateMode = this.config.date_mode
    }
    console.log(this.config)
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    let params = ReportHelper.getReportDatesParams(this.dateMode,
      null, null, null, null,
      this.dateGrouping, this.timezone, null, 'devices')

    console.log(params)
    this.startDate = params.start_date
    this.endDate = params.end_date

    if (!this.reportId) {
      console.error('No Report ID provided!')
    } else {
      let resp = await DataProvider.getReport(this.reportId)
      if (resp.success) {
        console.log(resp)
        this.report = resp.data
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

.report {
  display: flex;
  flex-direction: column;
  background-color: $default-content-background;
  width: 100%;
  overflow: auto;
  padding: 1%;
}

.report-heading {
  color: $text-color-heading;
  font-weight: 600;
  padding: 5px;
}

.report-header {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 60px);
  background: $theme-color-background-2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.icon-button {
  font-size: 2em;
}

@media print {

  @page {
    size: a4 landscape;
  }

  .report {
    overflow: hidden;
    justify-content: start;
    padding: 0;
  }

  .report-heading {
  }

  .report-header {
  }

  .report-header {
    display: none;
  }

  .b-tooltip {
    display: none;
  }
}

</style>
